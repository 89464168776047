import axios from 'axios';
import { store } from './app/store';
import { refreshToken } from './features/auth/authSlice';

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await store.dispatch(refreshToken());
                return axios(originalRequest);
            } catch (refreshError) {
                // Handle refresh token failure (e.g., logout user)
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);
